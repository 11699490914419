const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://38568z29x3.zicp.vip/doc.html#/yinzhilv-tmc/t-tp-refund-change-policy-controller/getUsingPOST_3
const consumer_t_tp_refund_change_policy_get = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-tp-refund-change-policy/get',
        data: pParameter
    };

    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_t_tp_refund_change_policy_get;