export default {
    name: "Calendar",
    data() {
        return {
            weekBtnStatus: true,
            weekNextBtnStatus: true,
            weekList: [],
            show: true
        };
    },
    created() {},
    props: ["saveWeek", "initStatus", "type"],
    watch: {
        saveWeek(val) {
            this.getDayList(val);
        },
        initStatus(val) {
            val ? this.getDayList(this.saveWeek) : "";
        }
    },
    mounted() {
        //初始刷新日历列表
        this.getDayList(this.saveWeek);
    },
    methods: {
        setTime(val) {
            const checkDay = val.date;
            if (this.type === "change") {
                // moment(checkDay) > moment().add(29, 'days') &&
                // return this.$message.warning("已超出可选日期");
                // 如果超过60天，则不能点击
                let startDay = this.$moment();
                let searchDay = this.$moment(val.date);
                const daySpacing = searchDay.diff(startDay, "days");
                if (daySpacing + 2 > 30) {
                    return;
                }
                this.weekList.map(item => {
                    item.status = false;
                });
                val.status = true;
                this.$emit("clickDate", val.date);
            } else {
                // 如果超过60天，则不能点击
                let startDay = this.$moment();
                let searchDay = this.$moment(val.date);
                const daySpacing = searchDay.diff(startDay, "days");
                if (daySpacing + 2 > 60) {
                    return;
                }
                this.weekList.map(item => {
                    item.status = false;
                });
                val.status = true;
                this.$emit("clickDate", val.date);
            }
        },
        getDayList(val) {
            if (this.type === "change") {
                let now = new Date().valueOf();
                let location = this.locationDay(3);
                let lastLocation = this.lastDay(3);
                let lastDay = now + 29 * 24 * 3600 * 1000;
                if (location - now <= 0) {
                    this._getWeek();
                    this.weekBtnStatus = false;
                    this.weekNextBtnStatus = true;
                } else if (lastDay - lastLocation < 0) {
                    this._getWeek(location - (lastLocation - lastDay));
                    this.weekBtnStatus = true;
                    this.weekNextBtnStatus = false;
                } else {
                    this._getWeek(location);
                    this.weekBtnStatus = true;
                    this.weekNextBtnStatus = true;
                }
            } else {
                let now = new Date().valueOf();
                let location = this.locationDay(3);
                let lastLocation = this.lastDay(3);
                let lastDay = now + 59 * 24 * 3600 * 1000;
                if (location - now <= 0) {
                    this._getWeek();
                    this.weekBtnStatus = false;
                    this.weekNextBtnStatus = true;
                } else if (lastDay - lastLocation < 0) {
                    this._getWeek(location - (lastLocation - lastDay));
                    this.weekBtnStatus = true;
                    this.weekNextBtnStatus = false;
                } else {
                    this._getWeek(location);
                    this.weekBtnStatus = true;
                    this.weekNextBtnStatus = true;
                }
            }
        },
        locationDay(val) {
            return Date.parse(this.saveWeek) - val * Math.floor(24 * 3600 * 1000);
        },
        lastDay(val) {
            return Date.parse(this.saveWeek) + val * Math.floor(24 * 3600 * 1000);
        },
        _getWeek(val) {
            let arr = [];
            val ? val : "";
            for (let i = 0; i < 7; i++) {
                let dateTime = this.$moment(val)
                    .subtract(-i, "days")
                    .format("YYYY-MM-DD");
                let dayTime = this.$moment(dateTime).format("MM-DD");
                let week = this.$moment(val).subtract(-i, "days")._d;
                week = week.getDay();
                week =
                    week === 0
                        ? "星期日"
                        : week === 1
                        ? "星期一"
                        : week === 2
                            ? "星期二"
                            : week === 3
                                ? "星期三"
                                : week === 4
                                    ? "星期四"
                                    : week === 5
                                        ? "星期五"
                                        : "星期六";
                let obj = {
                    week: week,
                    date: dateTime,
                    day: dayTime,
                    status: false
                };
                arr.push(obj);
            }
            this.weekList = arr;
            if (this.saveWeek) {
                arr.map((item, index) => {
                    if (item.date === this.saveWeek) {
                        item.status = true;
                    }
                });
            }
        },
        leftTime(val) {
            let _this = this;

            if (this.type === "change") {
                // const lastDay = this.weekList[6].date;
                // if (
                //   moment(lastDay) > moment().add(29, "days") &&
                //   this.type === "change"
                // ) {
                //   return;
                // }
                if (val === "next") {
                    if (!this.weekNextBtnStatus) return;
                    this.dayShow();
                    let date = this.$moment(this.weekList[6].date).format("YYYY-MM-DD");
                    let now = this.$moment().format("YYYY-MM-DD");
                    let dayNum =
                        Date.parse(date) +
                        6 * 24 * 3600 * 1000 -
                        (Date.parse(now) + 29 * 24 * 3600 * 1000);
                    if (dayNum >= 0) {
                        this.weekNextBtnStatus = false;
                        this._getWeek(this.weekList[6 - dayNum / 1000 / 3600 / 24].date);
                    } else {
                        this._getWeek(this.weekList[6].date);
                        this.weekNextBtnStatus = true;
                    }
                    this.weekBtnStatus = true;
                } else if (val === "last") {
                    if (!this.weekBtnStatus) return;
                    this.dayShow();
                    let date = this.$moment(this.weekList[0].date)
                        .subtract("days", 6)
                        .format("YYYY-MM-DD");
                    let now = this.$moment().format("YYYY-MM-DD");

                    let dayNum = Date.parse(date) - Date.parse(now);
                    if (dayNum <= 0) {
                        this._getWeek(now);
                    } else {
                        this._getWeek(date);
                    }
                    if (date.valueOf() <= now.valueOf()) {
                        this.weekBtnStatus = false;
                    }
                    this.weekNextBtnStatus = true;
                }
            } else {
                if (val === "next") {
                    if (!this.weekNextBtnStatus) return;
                    this.dayShow();
                    let date = this.$moment(this.weekList[6].date).format("YYYY-MM-DD");
                    let now = this.$moment().format("YYYY-MM-DD");
                    let dayNum =
                        Date.parse(date) +
                        6 * 24 * 3600 * 1000 -
                        (Date.parse(now) + 59 * 24 * 3600 * 1000);
                    if (dayNum >= 0) {
                        this.weekNextBtnStatus = false;
                        this._getWeek(this.weekList[6 - dayNum / 1000 / 3600 / 24].date);
                    } else {
                        this._getWeek(this.weekList[6].date);
                        this.weekNextBtnStatus = true;
                    }
                    this.weekBtnStatus = true;
                } else if (val === "last") {
                    if (!this.weekBtnStatus) return;
                    this.dayShow();
                    let date = this.$moment(this.weekList[0].date)
                        .subtract("days", 6)
                        .format("YYYY-MM-DD");
                    let now = this.$moment().format("YYYY-MM-DD");

                    let dayNum = Date.parse(date) - Date.parse(now);
                    if (dayNum <= 0) {
                        this._getWeek(now);
                    } else {
                        this._getWeek(date);
                    }
                    if (date.valueOf() <= now.valueOf()) {
                        this.weekBtnStatus = false;
                    }
                    this.weekNextBtnStatus = true;
                }
            }
        },
        dayShow() {
            this.show = false;
            setTimeout(() => {
                this.show = true;
            });
        },
        datedifference(sDate1, sDate2) {
            let dateSpan, tempDate, iDays;
            sDate1 = Date.parse(sDate1);
            sDate2 = Date.parse(sDate2);
            dateSpan = sDate2 - sDate1;
            dateSpan = Math.abs(dateSpan);
            iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
            return iDays;
        }
    }
};