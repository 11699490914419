// 获取地址栏出差单的信息
/*
* params:
* vm 传入vue实例（就是传入this）
* */
const getEvectionAllType = function (params) {
    if (!params.vm) {
        return null
    }
    const query = params.vm.$route.query
    let type = '' // 1:因公出差带出差单, 2: 因公出差不带出差单, 3:因私出差
    // 因公出差带出差单
    if (parseInt(query.evectionType) === 2 && parseInt(query.workTravelType) === 1) {
        type = '001'
    }
    // 因公出差不带出差单
    if (parseInt(query.evectionType) === 2 && parseInt(query.workTravelType) === 2) {
        type = '002'
    }
    // 因私出差
    if (parseInt(query.evectionType) === 1) {
        type = '101'
    }
    return {
        evectionDetailType: type, // '001':因公出差带出差单, '002': 因公出差不带出差单, '101':因私出差
        evectionNo: query.evectionNo, // 出差单号
        evectionType: parseInt(query.evectionType), // 出差类型： 1 因私，2因公
        evectionRequired: query.evectionRequired === undefined ? 0 : parseInt(query.evectionRequired) // 是否必须填入出差单， 1：是， 0 否
    }
}

export default getEvectionAllType