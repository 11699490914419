// 创建最新超标申请单,旧会被删除 http://219.128.52.2:18765/doc.html#/haolv-consumer/t-od-journey-controller/addOrUpdateOverproofUsingPOST
const __request = require(`./__request/__request_contentType_json`);
// import { Message } from 'element-ui';
const consumer_journey_addOrUpdateOverproof = (pParameter) => {
    if (!pParameter) pParameter = {};
    const _this = this;
    const p = {
        method: 'post',
        urlSuffix: '/consumer/journey/addOrUpdateOverproof',
        data: pParameter,
        alert: (pMsg) => {
            // Message.warning({
            //     message: pMsg
            // })
            console.log('this', this)
            _this.$message({
                type: "warning",
                message: pMsg
            })
        }
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_journey_addOrUpdateOverproof;